import { Box, Skeleton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { BORDER_RADIUS } from '../constants'
import { useConversationContext } from '../contexts/ConversationContext'
import ConversationBubbleDemo from './ConversationBubbleDemo'

const ConversationBubblesDemo = ({ viewportWidth }) => {
  const { conversation, waitingForReply } = useConversationContext()
  const theme = useTheme()

  const marginSize = viewportWidth > 0 ? Math.round(viewportWidth * 0.02) : 8

  const conversationBubbles = conversation
    .filter((message) => message.role !== 'system')
    .map((message) => {
      const { role, content, messageId } = message
      const backgroundColor = theme.palette.conversationBackground[role]
      const color = theme.palette.conversationColor[role]
      const position = role === 'user' ? 'flex-end' : 'flex-start'
      const styling = { color, backgroundColor, padding: 2, borderRadius: BORDER_RADIUS }
      const margin = position === 'flex-end' ? 'marginLeft' : 'marginRight'
      styling[margin] = marginSize

      return (
        <ConversationBubbleDemo
          content={content}
          messageId={messageId}
          styling={styling}
          position={position}
          role={role}
          key={`m_${messageId}`}
        />
      )
    })
  if (waitingForReply) {
    const loadingSkeleton = (
      <Box justifyContent="flex-start">
        <Skeleton variant="rounded" height={80} sx={{ borderRadius: BORDER_RADIUS, marginRight: marginSize }} />
      </Box>
    )
    conversationBubbles.push(loadingSkeleton)
  }

  return conversationBubbles
}

export default ConversationBubblesDemo
