import { LANGUAGES } from './constants'

const CACHE_KEY = 'fluentlyaiSettingsCache'
const DEFAULT_NOISE_THRESHOLD = 75

class SettingsCache {
  static get() {
    const cachedData = localStorage.getItem(CACHE_KEY)
    if (cachedData) {
      return JSON.parse(cachedData)
    }
    return {
      nativeLanguage: LANGUAGES[0],
      targetLanguage: LANGUAGES[0],
      noiseThreshold: DEFAULT_NOISE_THRESHOLD,
    }
  }

  static getNativeLanguage() {
    const cachedData = this.get()
    return cachedData?.nativeLanguage?.replace('-', '') || LANGUAGES[0]
  }

  static getTargetLanguage() {
    const cachedData = this.get()
    return cachedData?.targetLanguage?.replace('-', '') || LANGUAGES[0]
  }

  static getNoiseThreshold() {
    const cachedData = this.get()
    return cachedData?.noiseThreshold || DEFAULT_NOISE_THRESHOLD
  }

  static set(data) {
    localStorage.setItem(CACHE_KEY, JSON.stringify(data))
  }

  static updateNativeLanguage(newValue) {
    const cachedData = this.get()
    cachedData.nativeLanguage = newValue
    this.set(cachedData)
  }

  static updateTargetLanguage(newValue) {
    const cachedData = this.get()
    cachedData.targetLanguage = newValue
    this.set(cachedData)
  }

  static updateNoiseThreshold(newValue) {
    const cachedData = this.get()
    cachedData.noiseThreshold = newValue
    this.set(cachedData)
  }

  static clear() {
    localStorage.removeItem(CACHE_KEY)
  }
}

export default SettingsCache
