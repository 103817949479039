import React from 'react'

const defaultData = {
  email: '',
  loggedIn: false,
  preferredLanguage: 'enUS',
  subscriptionActive: false,
  username: '',
  verified: false,
}

const UserContext = React.createContext({
  userData: defaultData,
  updateUserData: () => null,
})

const UserContexProvider = ({ data, setData, ...props }) => {
  const updateUserData = React.useCallback((updatedData) => {
    setData(updatedData)
  }, [])
  const contextValue = React.useMemo(() => ({ userData: data, updateUserData }), [data, updateUserData])
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <UserContext.Provider value={contextValue} {...props} />
}

const useUserContext = () => React.useContext(UserContext)

export { UserContexProvider, useUserContext }
