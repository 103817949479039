const AUTH_TOKEN_KEY = 'fluentlyAuthToken'

class TokenCache {
  static getToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)
  }

  static setToken(token) {
    localStorage.setItem(AUTH_TOKEN_KEY, token)
  }

  static removeToken() {
    localStorage.setItem(AUTH_TOKEN_KEY, '')
  }
}

export default TokenCache
