import Utils from './Utils'

// TODO Experiment using this or something similar
// https://github.com/f/awesome-chatgpt-prompts#act-as-a-spoken-english-teacher-and-improver

/* Strings that should be localized */

const TRY_SAYING_SOMETHING = `Press "Speak" and try saying something`

/* App Constants */
const BORDER_RADIUS = 4
const GENRES = ['any', 'sci-fi', 'fantasy', 'mystery', 'romance', 'action', 'adventure', 'comedy', 'drama']
const LEVELS = ['beginner', 'intermediate', 'advanced']
const LANGUAGES = ['enUS', 'koKR', 'esUS', 'filPH', 'deDE', 'ruRU', 'fiFI', 'jaJP', 'cmnCN', 'itIT', 'trTR', 'hiIN']
const CODE_TO_DISPLAY_MAP = {
  enUS: 'English (US)',
  koKR: '한국어',
  esUS: 'Español (US)',
  filPH: 'Wikang Filipino',
  deDE: 'Deutsch',
  ruRU: 'Русский',
  fiFI: 'Suomi',
  jaJP: '日本語',
  cmnCN: '普通话',
  itIT: 'Italiano',
  trTR: 'Türkçe',
  hiIN: 'हिन्दी',
}

// For local testing when running via `npm start`
const TESTING = process.env.REACT_APP_TESTING === 'true'
const DOMAIN = TESTING ? 'http://localhost:8080' : ''

const TEST_ROWS = [
  { id: Utils.randomId(), text: 'Evanescent' },
  { id: Utils.randomId(), text: 'Abstruse' },
  { id: Utils.randomId(), text: 'Poignant' },
  { id: Utils.randomId(), text: 'Juxtaposition' },
  { id: Utils.randomId(), text: 'Idiosyncratic' },
  { id: Utils.randomId(), text: 'Succinct' },
  { id: Utils.randomId(), text: 'Perspicacious' },
  { id: Utils.randomId(), text: 'Ostensible' },
  { id: Utils.randomId(), text: 'Intrepid' },
  { id: Utils.randomId(), text: 'Prolific' },
  { id: Utils.randomId(), text: 'Clandestine' },
  { id: Utils.randomId(), text: 'Decipher' },
  { id: Utils.randomId(), text: 'Elucidate' },
  { id: Utils.randomId(), text: 'Gratuitous' },
  { id: Utils.randomId(), text: 'Inchoate' },
  { id: Utils.randomId(), text: 'Malevolent' },
  { id: Utils.randomId(), text: 'Obsequious' },
  { id: Utils.randomId(), text: 'Prestigious' },
  { id: Utils.randomId(), text: 'Recalcitrant' },
  { id: Utils.randomId(), text: 'Sedentary' },
]
const TEST_STORY = `In the evanescent twilight, amidst an abstruse forest, a poignant scene unfolded. A solitary firefly, with its idiosyncratic glow, danced in juxtaposition to the impenetrable darkness. Its tiny light flickered like a secret code, decipherable only by those who cared to look.
In the heart of the woods, a wise old owl perched on a gnarled oak, observing this luminous spectacle. The juxtaposition of the enigmatic forest and the firefly's ephemeral radiance struck a chord deep within its soul.
The owl contemplated the abstruse nature of existence, finding solace in the fleeting beauty of the firefly's light. It was a poignant reminder that life's mysteries could be both captivating and elusive, leaving a lasting impression on those who sought to understand the idiosyncratic harmony of the natural world.`

const demoConversationENShort = [
  { role: 'assistant', content: "Hey, how's it going?", messageId: 'enMessage0' },
  { role: 'user', content: 'Not too bad, thanks! How about you?', messageId: 'enMessage1' },
  {
    role: 'assistant',
    content: "I'm doing well, thanks for asking. Did you have a good weekend?",
    messageId: 'enMessage2',
  },
  {
    role: 'user',
    content: 'Yeah, it was pretty good. I went hiking with some friends and enjoyed the nice weather. What about you?',
    messageId: 'enMessage3',
  },
  {
    role: 'assistant',
    content:
      'Nice! I had a relaxing weekend. I caught up on some shows and did a bit of gardening. It was a nice change of pace.',
    messageId: 'enMessage4',
  },
]
const demoConversationEN = [
  { role: 'assistant', content: "Hey, how's it going?" },
  { role: 'user', content: 'Not too bad, thanks! How about you?' },
  { role: 'assistant', content: "I'm doing well, thanks for asking. Did you have a good weekend?" },
  {
    role: 'user',
    content: 'Yeah, it was pretty good. I went hiking with some friends and enjoyed the nice weather. What about you?',
  },
  {
    role: 'assistant',
    content:
      'Nice! I had a relaxing weekend. I caught up on some shows and did a bit of gardening. It was a nice change of pace.',
  },
  {
    role: 'user',
    content:
      "That sounds great. Sometimes it's nice to just take it easy and recharge. By the way, have you heard about that new movie that just came out?",
  },
  { role: 'assistant', content: 'Oh, which one are you talking about?' },
  {
    role: 'user',
    content:
      "It's called \"The Lost City.\" It's an adventure-comedy with a star-studded cast. I heard it's getting some positive reviews.",
  },
  {
    role: 'assistant',
    content:
      "I haven't heard of it, but it sounds interesting. I enjoy a good adventure-comedy. Maybe we should plan to watch it together sometime?",
  },
  {
    role: 'user',
    content:
      "That sounds like a plan! Let's check the showtimes and find a convenient day. I'll look it up online later.",
  },
  {
    role: 'assistant',
    content:
      "Perfect! I'm looking forward to it. Oh, and speaking of movies, have you seen any other good ones recently?",
  },
  {
    role: 'user',
    content:
      'Actually, I watched "The Midnight Sky" last week. It\'s a post-apocalyptic science fiction film directed by George Clooney. It had a compelling story and stunning visuals.',
  },
  {
    role: 'assistant',
    content:
      "Ah, yes, I've heard about that one. I've been meaning to watch it. Thanks for the recommendation. I'll add it to my watchlist.",
  },
  {
    role: 'user',
    content:
      "You're welcome! I think you'll enjoy it. So, aside from movies, is there anything exciting happening in your life lately?",
  },
  {
    role: 'assistant',
    content:
      'Not too much, to be honest. Just the usual work stuff and trying to find some time for hobbies. How about you? Anything exciting going on?',
  },
  {
    role: 'user',
    content:
      "Well, I'm actually planning a trip to Europe next month. I've been wanting to visit for a while, so I'm really looking forward to it.",
  },
  { role: 'assistant', content: "Wow, that's amazing! Which countries are you planning to visit?" },
  {
    role: 'user',
    content:
      "I'm going to start in France, explore some cities like Paris and Lyon, and then make my way to Italy, visiting Rome, Florence, and Venice. It's going to be quite an adventure.",
  },
  {
    role: 'assistant',
    content:
      "That sounds absolutely fantastic! I hope you have an incredible time and make some unforgettable memories. Don't forget to try the local cuisine.",
  },
  {
    role: 'user',
    content:
      "Thank you! I definitely won't miss out on the food. I'll make sure to indulge in some delicious French pastries and authentic Italian pizza and pasta.",
  },
  {
    role: 'assistant',
    content:
      "Now I'm getting jealous! But seriously, have a wonderful trip and take lots of pictures. I can't wait to hear all about it when you get back.",
  },
  {
    role: 'user',
    content:
      "I will, for sure! And hey, we should plan a get-together when I'm back, so I can share my travel stories and photos with you.",
  },
  {
    role: 'assistant',
    content: "Absolutely! Let's do that. It'll be great to catch up and hear about your adventures firsthand.",
  },
  {
    role: 'user',
    content: 'Sounds like a plan. Well, I should get going now. It was nice catching up with you. Take care!',
  },
  { role: 'assistant', content: 'You too! Have a safe trip and enjoy every moment. Take care and see you soon!' },
]

const demoConversationKR = [
  { role: 'assistant', content: '안녕, 어떻게 지내?', messageId: Utils.randomId() },
  { role: 'user', content: '별로야, 그냥 평소처럼 일에 바쁘게 지내고 있어. 너는?', messageId: Utils.randomId() },
  {
    role: 'assistant',
    content: '나도 마찬가지야. 때때로 끝이 없는 것 같아. 그런데, 동네에 새로 열린 식당 소식 들었어?',
    messageId: Utils.randomId(),
  },
  {
    role: 'user',
    content: '아, 지난 주에 오픈한 거 말하는 거야? 음, 몇 마디 들었어. 거기 가봤어?',
    messageId: Utils.randomId(),
  },
  {
    role: 'assistant',
    content: '아직은 아무래도, 그런데 이번 주말에 가볼까 생각 중이야. 메뉴가 다양하다고 하더라.',
    messageId: Utils.randomId(),
  },
  {
    role: 'user',
    content: '좋아! 알려줘. 나 요즘 음식에 지루함을 느끼고 있어서 새로운 추천이 필요해.',
    messageId: Utils.randomId(),
  },
  { role: 'assistant', content: '알았어! 그런데 어제 경기는 봤어?', messageId: Utils.randomId() },
  { role: 'user', content: '아니, 놓쳤어. 누가 이겼어?', messageId: Utils.randomId() },
  { role: 'assistant', content: '홈팀이 마지막 쿼터에 역전했어. 정말 긴장되는 경기였어.', messageId: Utils.randomId() },
  { role: 'user', content: '진짜? 하이라이트는 꼭 봐야겠다. 그거 놓치다니 정말 안됐네.', messageId: Utils.randomId() },
  {
    role: 'assistant',
    content: '응, 정말 대박이었어. 어쨌든, 주말에 식당 가기로 하면 알려줘.',
    messageId: Utils.randomId(),
  },
  { role: 'user', content: '당연히, 일상에서 벗어나기 딱 좋을 것 같아. 나도 기대돼!', messageId: Utils.randomId() },
]

export {
  TESTING,
  DOMAIN,
  TEST_ROWS,
  TEST_STORY,
  demoConversationENShort,
  demoConversationEN,
  demoConversationKR,
  TRY_SAYING_SOMETHING,
  BORDER_RADIUS,
  GENRES,
  LEVELS,
  LANGUAGES,
  CODE_TO_DISPLAY_MAP,
}
