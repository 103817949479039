import { AccountCircle, Copyright, HomeOutlined, ListAlt, Logout, Menu, Settings } from '@mui/icons-material'
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  SwipeableDrawer,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import { Howler } from 'howler'
import React from 'react'
import { Link } from 'react-router-dom'
import AccountDialog from '../AccountDialog'
import SettingsDialog from '../SettingsDialog'
import TokenCache from '../TokenCache'
import { DOMAIN, TESTING } from '../constants'
import { useCreditBalanceContext } from '../contexts/CreditBalanceContext'
import { useUserContext } from '../contexts/UserContext'
import DrawerItem from './DrawerItem'
import ThemeButtonItem from './ThemeButtonItem'

const TempDrawer = ({ currentTheme, handleThemeChange }) => {
  const theme = useTheme()
  const { userData, updateUserData } = useUserContext()
  const { balance } = useCreditBalanceContext()
  const { loggedIn } = userData

  const [creditsTooltipOpen, setCreditsTooltipOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [drawerItems, setDrawerItems] = React.useState([])
  const [accountDialogOpen, setAccountDialogOpen] = React.useState(false)
  const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false)

  const smAndDown = useMediaQuery((t) => t.breakpoints.down('sm'))
  const textColor = theme.palette.textColor.main

  const toggleDrawer = () => {
    setOpen((prevState) => !prevState)
  }

  let displayedCreditBalance = 0
  try {
    displayedCreditBalance = balance && !Number.isNaN(balance) ? balance.toFixed(0) : 0
  } catch (error) {
    console.error(error)
  }

  React.useEffect(() => {
    const verifyAndUpdateSession = async () => {
      const cachedToken = TokenCache.getToken()
      if (cachedToken !== null && cachedToken !== '') {
        const request = { method: 'GET', headers: { Authorization: cachedToken } }
        try {
          const response = await fetch(`${DOMAIN}/check-authorization`, request)
          if (response.status === 200) {
            const responseData = await response.json()
            updateUserData({ ...responseData, loggedIn: true, token: cachedToken })
          } else if (response.status === 401) {
            console.log('Unauthorized: current token is expired')
          }
        } catch (e) {
          console.error('There was a problem making the request to check your session token:', e)
        }
      }
    }
    setDrawerItems(
      [
        {
          text: 'Home',
          icon: <HomeOutlined />,
          mustBeLoggedIn: true,
          action: async () => {
            await verifyAndUpdateSession('/home')
            Howler.unload()
            setOpen(false)
          },
          toPath: '/home',
          id: `drawer-item-home`,
        },
        {
          text: 'Review List',
          icon: <ListAlt />,
          mustBeLoggedIn: true,
          action: async () => {
            Howler.unload()
            setOpen(false)
          },
          toPath: '/review',
          id: `drawer-item-review-list`,
        },
        {
          text: 'Account',
          icon: <AccountCircle />,
          mustBeLoggedIn: true,
          action: () => {
            setAccountDialogOpen(true)
            setOpen(false)
          },
          id: `drawer-item-account`,
        },
        {
          text: 'Settings',
          icon: <Settings />,
          mustBeLoggedIn: true,
          action: () => {
            setSettingsDialogOpen(true)
            setOpen(false)
          },
          id: `drawer-item-settings`,
        },
        {
          text: 'Sign Out',
          icon: <Logout />,
          mustBeLoggedIn: true,
          action: async () => {
            Howler.unload()
            TokenCache.removeToken()
            await verifyAndUpdateSession('/')
            const updatedUserData = { loggedIn: false }
            updateUserData(updatedUserData)
            setOpen(false)
          },
          toPath: '/',
          id: `drawer-item-signout`,
        },
        {
          text: (
            <span>
              {`2023-${new Date().getFullYear()} YT Projects LLC. All rights reserved.`}
              <Box>
                <Stack direction="row" width="100%" alignItems="center" spacing={1}>
                  <Typography>Questions? Bugs?</Typography>
                  <Link
                    color={textColor}
                    href="https://discord.gg/cQ2GAtxWsc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Discord
                  </Link>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>Created by:</Typography>
                  <Link
                    color={textColor}
                    href="https://twitter.com/youngtaiahn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @youngtaiahn
                  </Link>
                </Stack>
              </Box>
            </span>
          ),
          icon: <Copyright />,
          mustBeLoggedIn: false,
          action: null,
          toPath: null,
          id: `copyright`,
        },
      ]
        .filter((item) => (item.mustBeLoggedIn ? loggedIn : true))
        .map((item) => <DrawerItem item={item} key={item.id} />)
    )
  }, [loggedIn])

  const drawerContent = (
    <Box overflow="auto">
      {drawerItems.length > 0 && <List>{drawerItems}</List>}
      <ThemeButtonItem currentTheme={currentTheme} handleThemeChange={handleThemeChange} />
    </Box>
  )

  return (
    <>
      <AppBar
        position="fixed"
        variant="elevation"
        elevation={0}
        sx={{ zIndex: (t) => t.zIndex.drawer + 1, backgroundColor: 'inherit', color: 'inherit' }}
      />
      <Toolbar variant="regular">
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} width="100%">
          <Stack direction="row" width="50%" justifyContent="flex-start">
            {loggedIn && balance && (
              <Tooltip
                arrow
                title={`${balance} credits remaining`}
                open={creditsTooltipOpen}
                onClose={() => setCreditsTooltipOpen(false)}
                onOpen={() => setCreditsTooltipOpen(true)}
              >
                <Button onClick={() => setCreditsTooltipOpen((prevState) => !prevState)}>
                  <Typography sx={{ fontFamily: 'Raleway' }}>{displayedCreditBalance}</Typography>
                </Button>
              </Tooltip>
            )}
          </Stack>
          <Typography variant="h5" sx={{ fontFamily: 'Raleway', fontWeight: 600 }}>
            {`Fluently${TESTING ? ' (testing)' : ''}`}
          </Typography>
          <Stack direction="row" width="50%" justifyContent="flex-end">
            <IconButton onClick={toggleDrawer}>
              <Menu />
            </IconButton>
          </Stack>
        </Stack>
      </Toolbar>
      <SwipeableDrawer
        disableBackdropTransition
        disableDiscovery
        anchor={smAndDown ? 'bottom' : 'right'}
        variant="temporary"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        PaperProps={{
          style: { backgroundColor: theme.palette.backgroundColor.main, borderRadius: 6 },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawerContent}
        <Box height={24} />
      </SwipeableDrawer>
      <AccountDialog open={accountDialogOpen} setOpen={setAccountDialogOpen} />
      <SettingsDialog open={settingsDialogOpen} setOpen={setSettingsDialogOpen} />
    </>
  )
}

export default TempDrawer
