import React from 'react'
import { Stack, Typography } from '@mui/material'

export default function LabelValue({ label, value }) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography fontWeight={600}>{label}:</Typography>
      <Typography>{value}</Typography>
    </Stack>
  )
}
