import { createTheme } from '@mui/material/styles'
import { darkScrollbar } from '@mui/material'

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      lightest: '#FEFCFB',
      light: '#f3f4fa',
      main: '#1282a2',
      dark: '#034078',
      darker: '#001f54',
      darkest: '#0a1128',
    },
    secondary: {
      light: '#e0b1cb',
      main: '#be95c4',
      dark: '#9f86c0',
      darker: '#5e548e',
      darkest: '#231942',
    },
    backgroundColor: {
      main: '#f8f8ff',
      darker: '#DFDFE5',
      highlight: '#dae7ff',
    },
    textColor: {
      main: '#232933',
      highlight: '#3a4353',
    },
    conversationBackground: {
      user: '#f3f4fa',
      assistant: '#e0b1cb',
    },
    conversationColor: {
      user: 'black',
      assistant: 'black',
    },
    messagesArea: '#FEFCFB',
    startButton: {
      backgroundColor: '#00a27a',
      hover: '#005a44',
    },
    paymentButton: {
      backgroundColor: '#336633',
      hover: '#234723',
    },
    subscriptionArea: {
      backgroundColor: '#339933',
      color: 'white',
    },
  },
  spacing: 6,
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#b2c2de',
      main: '#91a8d0',
      dark: '#657591',
      darker: '#414b5d',
      darkest: '#171b21',
    },
    secondary: {
      lightest: '#fdf4f4',
      lighter: '#fbe9e9',
      light: '#fadfde',
      main: '#f7cac9',
      dark: '#ac8d8c',
      darker: '#7b6564',
      darkest: '#4a3c3c',
    },
    backgroundColor: {
      main: '#2b323e',
      darker: '#222831',
      highlight: '#dfdfff',
    },
    textColor: {
      main: '#b2c2de',
      highlight: '#3a4353',
    },
    conversationBackground: {
      user: '#5b7a9d',
      assistant: '#3b2a2a',
    },
    conversationColor: {
      user: '#d6e0ff',
      assistant: '#fbe9e9',
    },
    messagesArea: '#222831',
    startButton: {
      backgroundColor: '#005a44',
      hover: '#00a27a',
    },
    paymentButton: {
      backgroundColor: '#336633',
      hover: '#234723',
    },
    subscriptionArea: {
      backgroundColor: '#194719',
      color: '#E5EAE5',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar(),
      },
    },
  },
  spacing: 6,
})
