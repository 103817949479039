import { Alert, CssBaseline, Snackbar, Toolbar } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import TokenCache from '../TokenCache'
import VerificationInvitationPage from '../VerificationInvitationPage'
import { DOMAIN } from '../constants'
import { useUserContext } from '../contexts/UserContext'
import TempDrawer from './TempDrawer'
import LandingPage from '../LandingPage'
import { useCreditBalanceContext } from '../contexts/CreditBalanceContext'
import ConversationBase from '../conversation/ConversationBase'
import {
  PushToTalkChatPage,
  CreateContentPage,
  GuidedPracticePage,
  HandsFreeChatPage,
  Home,
  LoginPage,
  PronunciationLabPage,
  RegisterPage,
  ReviewPage,
  StudyPage,
} from './LazyComponents'
import NotFound from './NotFound'

const { userAgent } = navigator
console.log(userAgent)

export default function BasePage({ handleThemeChange, currentTheme }) {
  const { userData, updateUserData } = useUserContext()
  const { updateBalance } = useCreditBalanceContext()
  const { loggedIn } = userData

  const theme = useTheme()
  const cachedToken = TokenCache.getToken()

  const [alertData, setAlertData] = React.useState({ show: false, message: '', level: 'error' })

  useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      if (cachedToken !== null && cachedToken !== undefined && cachedToken !== '') {
        try {
          const request = { method: 'GET', headers: { Authorization: cachedToken } }
          const response = await fetch(`${DOMAIN}/check-authorization`, request)
          if (response.status === 401) {
            const updatedUserData = { ...userData, loggedIn: false }
            updateUserData(updatedUserData)
            return new Error('Unauthorized: current token is probably expired')
          }
          if (response.status === 200) {
            const responseData = await response.json()
            const updatedUserData = { ...responseData, loggedIn: true, token: cachedToken }
            updateUserData(updatedUserData)
            updateBalance(responseData.creditsBalance)
            return Promise.resolve(responseData)
          }
          return new Error('Unable to check this session')
        } catch (e) {
          const updatedUserData = { ...userData, loggedIn: false }
          updateUserData(updatedUserData)
          return new Error('There was a problem making the request to check your session token:', e)
        }
      } else {
        const updatedUserData = { ...userData, loggedIn: false }
        updateUserData(updatedUserData)
        return new Error('No token to check')
      }
    },
    staleTime: 1000 * 60 * 60,
  })

  const alertSnackbar = (
    <Snackbar
      open={alertData.show}
      onClose={() => setAlertData({ ...alertData, show: false })}
      autoHideDuration={8000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ marginBottom: 6 }}
    >
      <Alert severity={alertData.level} onClose={() => setAlertData({ ...alertData, show: false })}>
        {alertData.message}
      </Alert>
    </Snackbar>
  )

  return (
    <Box
      display="flex"
      width="100%"
      color={theme.palette.textColor.main}
      bgcolor={theme.palette.backgroundColor.main}
      sx={{
        // backgroundImage: `url(${theme?.palette?.mode === 'light' ? backgroundImageLight : backgroundImageDark})`,
        backgroundSize: 'cover',
      }}
    >
      <CssBaseline />
      {alertSnackbar}
      <Box
        component="main"
        sx={{
          height: '100vh',
          p: 0,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TempDrawer currentTheme={currentTheme} handleThemeChange={handleThemeChange} />
        <Box display="flex" flexDirection="column" height="100%" sx={{ overflowY: 'auto' }}>
          <Routes>
            <Route exact path="/" element={loggedIn ? <Home /> : <LandingPage />} />
            <Route exact path="/login" element={loggedIn ? <Home /> : <LoginPage />} />
            <Route exact path="/register" element={loggedIn ? <Home /> : <RegisterPage />} />
            <Route exact path="/home" element={loggedIn ? <Home /> : <LandingPage />} />
            <Route exact path="/conversation-base" element={loggedIn ? <ConversationBase /> : <LandingPage />} />
            <Route exact path="/conversation" element={loggedIn ? <PushToTalkChatPage /> : <LandingPage />} />
            <Route exact path="/hands-free-chat" element={loggedIn ? <HandsFreeChatPage /> : <LandingPage />} />
            <Route exact path="/review" element={loggedIn ? <ReviewPage /> : <LandingPage />} />
            <Route exact path="/review/create" element={loggedIn ? <CreateContentPage /> : <LandingPage />} />
            <Route exact path="/review/study" element={loggedIn ? <StudyPage /> : <LandingPage />} />
            <Route exact path="/guided-practice" element={loggedIn ? <GuidedPracticePage /> : <LandingPage />} />
            <Route exact path="/pronunciation-lab" element={<PronunciationLabPage />} />
            <Route exact path="/unverified" element={loggedIn ? <VerificationInvitationPage /> : <LandingPage />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
        <Toolbar />
      </Box>
    </Box>
  )
}
