import { useTheme } from '@emotion/react'
import { Box, Dialog, Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import LemonSqueezyCreditCheckout from './LemonSqueezyCreditCheckout'
import LemonSqueezySubscriptionCheckout from './LemonSqueezySubscriptionCheckout'

const PaymentDialog = ({ dialogOpen, setDialogOpen, setAlertMessage }) => (
  <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
    <PaymentDialogContent setDialogOpen={setDialogOpen} setAlertMessage={setAlertMessage} />
  </Dialog>
)

const PaymentDialogContent = ({ setDialogOpen, setAlertMessage, hidePaymentButtons = false }) => {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.backgroundColor.main,
        color: theme.palette.textColor.main,
        height: '100%',
      }}
    >
      <Grid2 container justifyContent="center">
        <Grid2 sm={6} width="100%">
          <Stack spacing={2} padding={4} alignItems="center" width="100%" height="100%">
            <Typography variant="h5" sx={{ fontFamily: 'Raleway' }}>
              One-time Payment
            </Typography>
            <Typography variant="subtitle1" align="center" sx={{ fontFamily: 'Raleway' }}>
              I just want to dabble
            </Typography>
            <Stack direction="row" alignItems="flex-end" spacing={1}>
              <Typography variant="h5" sx={{ fontFamily: 'Raleway' }}>
                $1.99
              </Typography>
              <Typography sx={{ fontFamily: 'Raleway' }}>for 2000 credits</Typography>
            </Stack>
            <Box flexGrow={1} />
            {!hidePaymentButtons && <LemonSqueezyCreditCheckout />}
          </Stack>
        </Grid2>
        <Grid2 sm={6} width="100%">
          <Stack
            spacing={2}
            padding={4}
            alignItems="center"
            width="100%"
            height="100%"
            backgroundColor={theme.palette.subscriptionArea.backgroundColor}
            color={theme.palette.subscriptionArea.color}
          >
            <Typography variant="h5" sx={{ fontFamily: 'Raleway' }}>
              Subscription
            </Typography>
            <Typography variant="subtitle1" align="center" sx={{ fontFamily: 'Raleway' }}>
              I want to practice and learn regularly
            </Typography>
            <Stack direction="row" alignItems="flex-end" spacing={1}>
              <Typography variant="h5" sx={{ fontFamily: 'Raleway' }}>
                $14.99
              </Typography>
              <Typography sx={{ fontFamily: 'Raleway' }}>/month</Typography>
            </Stack>
            <Box flexGrow={1} />
            {!hidePaymentButtons && (
              <LemonSqueezySubscriptionCheckout setDialogOpen={setDialogOpen} setAlertMessage={setAlertMessage} />
            )}
          </Stack>
        </Grid2>
      </Grid2>
    </Stack>
  )
}

export { PaymentDialog, PaymentDialogContent }
