import React from 'react'

const ConversationContext = React.createContext({
  conversation: [],
  waitingForReply: false,
  targetLanguage: '',
  conversationLevel: '',
  startRecording: () => {},
  stopRecording: () => {},
})

const ConversationContextProvider = ({
  conversation,
  waitingForReply,
  targetLanguage,
  conversationLevel,
  startRecording = () => {},
  stopRecording = () => {},
  ...props
}) => {
  const providerValue = React.useMemo(
    () => ({
      conversation,
      waitingForReply,
      targetLanguage,
      conversationLevel,
      startRecording,
      stopRecording,
    }),
    [conversation, waitingForReply, targetLanguage, conversationLevel, startRecording, stopRecording]
  )
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ConversationContext.Provider value={providerValue} {...props} />
}

const useConversationContext = () => React.useContext(ConversationContext)

export { ConversationContextProvider, useConversationContext }
