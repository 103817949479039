import { Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import TokenCache from './TokenCache'
import { useUserContext } from './contexts/UserContext'
import { TESTING, DOMAIN } from './constants'
import { useCreditBalanceContext } from './contexts/CreditBalanceContext'

const LemonSqueezySubscriptionCheckout = ({ setDialogOpen, setAlertMessage }) => {
  const theme = useTheme()
  const { userData, updateUserData } = useUserContext()
  const { updateBalance } = useCreditBalanceContext()

  const cachedToken = TokenCache.getToken()

  const themeParameter = theme.palette.mode === 'light' ? 0 : 1
  const subscriptionCheckoutURL = TESTING
    ? `https://chatfluently.lemonsqueezy.com/checkout/buy/3e081047-9ae1-413e-8cb9-f4d687e3e70a?embed=1&media=0&logo=0&discount=0&dark=${themeParameter}&checkout[email]=${userData.email}`
    : `https://chatfluently.lemonsqueezy.com/checkout/buy/10774d95-4032-4c67-8994-5193f2605ad8?embed=1&media=0&logo=0&discount=0&dark=${themeParameter}&checkout[email]=${userData.email}`

  React.useEffect(() => {
    window.createLemonSqueezy()

    // eslint-disable-next-line no-undef
    LemonSqueezy.Setup({
      eventHandler: async (event) => {
        if (event.event === 'Checkout.Success') {
          try {
            const { order } = event.data
            const requestConfigs = {
              method: 'POST',
              body: JSON.stringify(order),
              headers: { Authorization: cachedToken, 'Content-Type': 'application/json' },
            }

            const productType = order?.data?.attributes?.first_order_item?.product_name

            if (productType === 'One-time Payment') {
              const response = await fetch(`${DOMAIN}/account/credits/create-order`, requestConfigs)

              if (!response.ok) {
                console.error('Something went wrong saving this order', response)
                setAlertMessage('Something went wrong saving this order.')
              }

              const responseBody = await response.json()
              const { creditsBalance } = responseBody
              updateBalance(creditsBalance)
              setDialogOpen(false)
            } else if (productType === 'Subscription') {
              const response = await fetch(`${DOMAIN}/account/subscription/create-order`, requestConfigs)
              if (!response.ok) {
                console.error('Something went wrong saving subscription state', response)
                setAlertMessage('Something went wrong updating your subscription status.')
              }
              updateUserData({ ...userData, subscription: { active: true } })
              console.log('Subscription state updated successfully')
              setDialogOpen(false)
            } else {
              const errorMessage = `ERROR: Can't handle this type of order: ${productType}`
              console.error(errorMessage)
              setAlertMessage(errorMessage)
              setDialogOpen(false)
            }
          } catch (error) {
            console.error(error)
            setAlertMessage(error.message)
            setDialogOpen(false)
          }
        }
      },
    })
  }, [])

  return (
    <Button
      component={RouterLink}
      to={subscriptionCheckoutURL}
      sx={{
        color: theme.palette.subscriptionArea.color,
        backgroundColor: theme.palette.paymentButton.backgroundColor,
        ':hover': { backgroundColor: theme.palette.paymentButton.hover },
        fontSize: '1.05rem',
        maxWidth: 400,
        padding: 2,
      }}
      className="lemonsqueezy-button"
      variant="contained"
    >
      <Typography align="center">Subscribe</Typography>
    </Button>
  )
}

export default LemonSqueezySubscriptionCheckout
