import { toByteArray } from 'base64-js'
import { Howl } from 'howler'
import TokenCache from '../TokenCache'
import { DOMAIN } from '../constants'

export default class ConversationService {
  static transcribeSpeech = async (audioURL, conversation, language) => {
    const resolveObjectURL = async (url) => {
      try {
        const response = await fetch(url)
        return await response.blob()
      } catch (error) {
        return new Error(`Error resolving audio URL to a blob: ${error.message}`)
      }
    }

    try {
      const audioBlob = await resolveObjectURL(audioURL)
      const audioFile = new File([audioBlob], 'temp-speech', { type: audioBlob.type })

      const body = new FormData()
      body.append('audioFile', audioFile)
      body.append('conversation', JSON.stringify(conversation))

      const request = {
        method: 'POST',
        headers: { Authorization: TokenCache.getToken() },
        body,
      }
      const response = await fetch(`${DOMAIN}/transcribe?language=${language}`, request)
      if (!response.ok) {
        if (response.status === 401) {
          return ''
        }
        console.error('There was a problem while trying transcribe speech')
        return ''
      }
      const responseBody = await response.json()
      return responseBody.text
    } catch (error) {
      console.error('Error transcribing audio:', error)
      return ''
    }
  }

  static createChatCompletion = async (
    messages,
    targetLanguage,
    startPrompt = false,
    guidancePrompt = true,
    conversationLevel = 'beginner',
    temp = 1,
    getSpeech = false,
    onPlayAction = () => null,
    onEndAction = () => null
  ) => {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: TokenCache.getToken() },
      body: JSON.stringify({ messages }),
    }
    const queryParams = [
      `temp=${temp}`,
      `targetLanguage=${targetLanguage}`,
      `guidancePrompt=${guidancePrompt}`,
      `conversationLevel=${conversationLevel}`,
      `startPrompt=${startPrompt}`,
      `getSpeech=${getSpeech}`,
    ].join('&')
    try {
      const response = await fetch(`${DOMAIN}/chat?${queryParams}`, request)
      if (!response.ok) {
        const { status, message } = response
        if (
          status === 401 &&
          (message === 'JWT Error: jwt expired' || message === 'Request missing an authorization token')
        ) {
          throw new Error(`You need to sign in again. Try refreshing the page.`)
        } else if (status === 401 && message === 'You must be subscribed to have a conversation.') {
          throw new Error('You must be subscribed to chat')
        }
        throw new Error('There was a problem while trying to chat')
      }
      const { completionContent, base64, creditsBalance } = await response.json()

      if (base64) {
        const binaryData = toByteArray(base64)
        // TODO https://github.com/goldfire/howler.js/#format-recommendations
        const blob = new Blob([binaryData], { type: 'audio/mpeg' })
        const url = URL.createObjectURL(blob)
        const speech = new Howl({ src: [url], format: 'mp3', onplay: onPlayAction, onend: onEndAction })
        return { completionContent, speech, creditsBalance }
      }
      return { completionContent, creditsBalance }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  static createStreamingChatCompletion = async (params) => {
    const {
      messages,
      targetLanguage,
      nativeLanguage,
      setDisplayedMessage,
      startPrompt = false,
      guidancePrompt = true,
      goalData = null,
      conversationLevel = 'beginner',
      temp = 1,
      getSpeech = false,
      onPlayAction = () => null,
      onEndAction = () => null,
    } = params

    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: TokenCache.getToken() },
      body: JSON.stringify({
        messages,
        temp,
        targetLanguage,
        nativeLanguage,
        guidancePrompt,
        conversationLevel,
        startPrompt,
        getSpeech,
        goalData,
      }),
    }

    try {
      const response = await fetch(`${DOMAIN}/chat/streaming`, request)
      if (!response.ok) {
        const { status, message } = response
        if (
          status === 401 &&
          (message === 'JWT Error: jwt expired' || message === 'Request missing an authorization token')
        ) {
          throw new Error(`You need to sign in again. Try refreshing the page.`)
        } else if (status === 401 && message === 'You must be subscribed to have a conversation.') {
          throw new Error('You must be subscribed to chat')
        }
        throw new Error('There was a problem while trying to chat')
      }

      if (startPrompt && goalData === null) {
        const { completionContent, base64, creditsBalance, startMessages } = await response.json()
        setDisplayedMessage(completionContent)

        if (base64) {
          const binaryData = toByteArray(base64)
          // TODO https://github.com/goldfire/howler.js/#format-recommendations
          const blob = new Blob([binaryData], { type: 'audio/mpeg' })
          const url = URL.createObjectURL(blob)
          const speech = new Howl({ src: [url], format: 'mp3', onplay: onPlayAction, onend: onEndAction })
          return { completionContent, speech, creditsBalance, systemPromptMessages: startMessages }
        }
        return { completionContent, creditsBalance }
      }

      const reader = response.body.getReader()
      const textDecoder = new TextDecoder('utf-8')
      const audioQueue = []
      const allContent = []
      let balance = null
      let systemPromptMessages = null

      const playNextChunk = async (chunkIndex) => {
        if (audioQueue[chunkIndex] === undefined) {
          // console.log('Chunk not retrieved yet, wait 100ms')
          setTimeout(() => playNextChunk(chunkIndex), 100)
          return
        }

        const { speechChunk, done } = audioQueue[chunkIndex]
        if (done) {
          onEndAction()
          return
        }

        if (allContent[chunkIndex]) {
          setDisplayedMessage((prevState) => `${prevState}${allContent[chunkIndex]}`)
        }

        const binaryData = toByteArray(speechChunk)
        const blob = new Blob([binaryData], { type: 'audio/mpeg' })
        const url = URL.createObjectURL(blob)
        const speech = new Howl({
          src: [url],
          format: 'mp3',
          onplay: chunkIndex === 0 ? onPlayAction : () => null,
          onend: () => playNextChunk(chunkIndex + 1),
        })
        speech.play()
      }

      let valueParts = []
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read()
        if (done) {
          audioQueue.push({ speechChunk: null, done })
          break
        }

        const decoded = textDecoder.decode(value)
        valueParts.push(decoded)

        if (decoded.endsWith('}')) {
          const jsonStrings = `[${valueParts.join('').replaceAll('}{', '},{')}]`
          valueParts = []

          const jsons = JSON.parse(jsonStrings)
          // eslint-disable-next-line no-loop-func
          jsons.forEach((json) => {
            const { completionChunk, speechChunk, creditsBalance, startMessages } = json
            balance = creditsBalance
            systemPromptMessages = startMessages
            // if (completionChunk) {
            //   setDisplayedMessage((prevState) => `${prevState}${completionChunk}`)
            // }
            if (speechChunk) {
              audioQueue.push({ speechChunk, done })
              allContent.push(completionChunk)
              if (audioQueue.length === 1) {
                playNextChunk(0)
              }
            }
          })
        }
      }

      return { completionContent: allContent.join(''), creditsBalance: balance, systemPromptMessages }
    } catch (error) {
      console.error(error)
      throw new Error(error.message)
    }
  }

  /**
   * Used in ConversationBubble only now
   * @param {String} text
   * @param {String} targetLanguage
   * @param {String} conversationLevel
   * @param {Function} updateBalance
   * @param {Function} onPlayAction
   * @param {Function} onEndAction
   * @returns `Howl` or `null`
   */
  static synthesizeSpeech = async (
    text,
    targetLanguage,
    conversationLevel,
    updateBalance,
    onPlayAction,
    onEndAction
  ) => {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: TokenCache.getToken() },
      body: JSON.stringify({ text, conversationLevel, targetLanguage }),
    }
    try {
      const response = await fetch(`${DOMAIN}/synthesize`, request)
      if (!response.ok) {
        if (response.status === 401) {
          return null
        }
        console.error('Request to synthesize speech was not ok')
        return null
      }
      const responseBody = await response.json()
      const { base64, creditsBalance } = responseBody
      if (creditsBalance) {
        updateBalance(creditsBalance)
      }
      const binaryData = toByteArray(base64)
      // TODO https://github.com/goldfire/howler.js/#format-recommendations
      const blob = new Blob([binaryData], { type: 'audio/mpeg' })
      const url = URL.createObjectURL(blob)
      const speech = new Howl({ src: [url], format: 'mp3', onplay: onPlayAction, onend: onEndAction })
      return speech
    } catch (error) {
      console.log(error)
      return null
    }
  }

  static getSpeechForDemo = async (messageId, onPlayAction, onEndAction) => {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ messageId }),
    }
    try {
      const response = await fetch(`${DOMAIN}/synthesize/demo`, request)
      if (!response.ok) {
        console.error('Request to synthesize speech was not ok')
        return null
      }
      const responseBody = await response.json()
      const binaryData = toByteArray(responseBody.base64)
      const blob = new Blob([binaryData], { type: 'audio/mpeg' })
      const url = URL.createObjectURL(blob)
      const speech = new Howl({ src: [url], format: 'mp3', onplay: onPlayAction, onend: onEndAction })
      return speech
    } catch (error) {
      console.log(error)
      return null
    }
  }
}
