import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'

const LandingPageSkeleton = () => (
  <Stack direction="row" height="100%" width="100%" justifyContent="center">
    <Stack height="100%" alignItems="center" spacing={2} width="100%" maxWidth={400}>
      <Box flexGrow={1} />
      <Skeleton variant="rounded" height="3.5rem" width="75%" />
      <Skeleton variant="rounded" height="2rem" width="95%" />
      <Box flexGrow={0.7} />
      <Skeleton variant="rounded" height="3.5rem" width="70%" />
      <Skeleton variant="rounded" height="3rem" width="40%" />
    </Stack>
  </Stack>
)

export default LandingPageSkeleton
