import { DarkMode, LightMode, SettingsBrightness } from '@mui/icons-material'
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'

const ThemeButtonItem = ({ handleThemeChange, currentTheme }) => (
  <ListItem key="theme-switch-item">
    <Stack direction="row" width="100%" justifyContent="center">
      <ToggleButtonGroup
        value={currentTheme}
        exclusive
        onChange={(event, newState) => handleThemeChange(event, newState)}
        size="medium"
      >
        <ToggleButton value="dark">
          <Grid2 container spacing={1}>
            <Grid2 display="flex" justifyContent="center" alignItems="center">
              <DarkMode />
            </Grid2>
            <Grid2 display="flex" justifyContent="center" alignItems="center">
              <Typography fontSize=".9rem">Dark</Typography>
            </Grid2>
          </Grid2>
        </ToggleButton>
        <ToggleButton value="system">
          <Grid2 container spacing={1}>
            <Grid2 display="flex" justifyContent="center" alignItems="center">
              <SettingsBrightness />
            </Grid2>
            <Grid2 display="flex" justifyContent="center" alignItems="center">
              <Typography fontSize=".9rem">System</Typography>
            </Grid2>
          </Grid2>
        </ToggleButton>
        <ToggleButton value="light">
          <Grid2 container spacing={1}>
            <Grid2 display="flex" justifyContent="center" alignItems="center">
              <LightMode />
            </Grid2>
            <Grid2 display="flex" justifyContent="center" alignItems="center">
              <Typography fontSize=".9rem">Light</Typography>
            </Grid2>
          </Grid2>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  </ListItem>
)

export default ThemeButtonItem
