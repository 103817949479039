import { Pause, PlayArrow } from '@mui/icons-material'
import { Stack, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React from 'react'

const SpeechControlButtonsDemo = ({ speech, setSpeech, getSpeech, speaking, setSpeaking }) => {
  const synthesizeAndPlay = async () => {
    const synthesizedSpeech = await getSpeech()
    if (synthesizedSpeech) {
      setSpeech(synthesizedSpeech)
      synthesizedSpeech.play()
    }
  }

  const playPauseTooltip = speaking ? 'Pause speech' : 'Resume speech'
  const handlePlayOrPause = async () => {
    if (speaking && speech) {
      speech.pause()
      setSpeaking(false)
    } else if (!speech) {
      synthesizeAndPlay()
    } else {
      speech.play()
    }
  }
  const playPauseIcon = speaking ? <Pause /> : <PlayArrow />

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={playPauseTooltip} placement="top">
        <IconButton onClick={handlePlayOrPause}>{playPauseIcon}</IconButton>
      </Tooltip>
    </Stack>
  )
}

export default SpeechControlButtonsDemo
