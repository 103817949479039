import { Check, Copyright, Done, ExpandMore, PlayArrow, Spellcheck, Translate } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { default as Grid, default as Grid2 } from '@mui/material/Unstable_Grid2/Grid2'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { PaymentDialogContent } from './PaymentDialog'
import { demoConversationENShort } from './constants'
import { ConversationContextProvider } from './contexts/ConversationContext'
import MessagesAreaDemo from './conversation/MessagesAreaDemo'

const LandingPage = () => {
  const theme = useTheme()

  const textColor = theme.palette.textColor.main
  const backgroundColorDarker = theme.palette.backgroundColor.darker

  return (
    <>
      <Stack spacing={8} alignItems="center">
        <Grid2 container spacing={12} alignItems="center" justifyContent="center">
          <Grid2>
            <Stack spacing={2}>
              <Box flexGrow={0.1} />
              <Typography variant="h5" align="center" sx={{ fontFamily: 'Raleway', fontWeight: 200 }}>
                How do I get better at speaking?
              </Typography>
              <Typography variant="h5" align="center" sx={{ fontFamily: 'Raleway', fontWeight: 200 }}>
                Answer: By speaking!
              </Typography>
            </Stack>
            <Box height={24} />
            <Typography variant="h5" align="center" sx={{ fontFamily: 'Raleway', fontWeight: 600 }}>
              Practice speaking now with AI
            </Typography>
          </Grid2>
          <Grid2>
            <Box>
              <Stack spacing={2} alignItems="center" justifyContent="flex-end">
                <Button
                  sx={{ padding: 2, fontSize: '1.05rem', maxWidth: 350, minWidth: 200 }}
                  size="large"
                  component={RouterLink}
                  to="/register"
                  variant="contained"
                >
                  Start practicing for free
                </Button>
                <Button
                  sx={{
                    color: textColor,
                    padding: 2,
                    fontSize: '1.05rem',
                    maxWidth: 350,
                    minWidth: 200,
                  }}
                  size="large"
                  component={RouterLink}
                  to="/login"
                  variant="text"
                >
                  Sign in here
                </Button>
              </Stack>
            </Box>
          </Grid2>
        </Grid2>

        <Stack direction="row" width="100%" backgroundColor={backgroundColorDarker} justifyContent="center">
          <Container maxWidth="sm">
            <Stack spacing={8} paddingY={4} paddingX={2}>
              <Typography variant="h4" align="center" sx={{ fontFamily: 'Raleway' }}>
                Have you ever said to yourself
              </Typography>

              <Box paddingRight={12}>
                <Paper
                  elevation={4}
                  sx={{ padding: 2, marginRight: 6, backgroundColor: theme.palette.backgroundColor.main }}
                >
                  <Typography variant="body" style={{ whiteSpace: 'pre-line' }} sx={{ fontFamily: 'Raleway' }}>
                    {`"I study so much but I can hardly speak."`}
                  </Typography>
                </Paper>
                <Box height={10} />
                <Box sx={{ padding: 2, marginLeft: 6 }}>
                  <Typography variant="subtitle2" fontWeight={600} sx={{ fontFamily: 'Raleway' }}>
                    You improve speaking (and listening) by speaking and listening a lot!
                  </Typography>
                </Box>
              </Box>

              <Box paddingLeft={12}>
                <Paper
                  elevation={4}
                  sx={{ padding: 2, marginRight: 6, backgroundColor: theme.palette.backgroundColor.main }}
                >
                  <Typography variant="body" sx={{ fontFamily: 'Raleway' }}>
                    {`"Private tutoring with a native speaker is expensive."`}
                  </Typography>
                </Paper>
                <Box height={10} />
                <Box sx={{ padding: 2, marginLeft: 6 }}>
                  <Typography variant="subtitle2" fontWeight={600} sx={{ fontFamily: 'Raleway' }}>
                    Practice here at a fraction of the cost to supplement or replace private tutoring!
                  </Typography>
                </Box>
              </Box>

              <Box paddingRight={12}>
                <Paper
                  elevation={4}
                  sx={{ padding: 2, marginRight: 6, backgroundColor: theme.palette.backgroundColor.main }}
                >
                  <Typography variant="body" sx={{ fontFamily: 'Raleway' }}>
                    {`"Speaking with real people is too scary."`}
                  </Typography>
                </Paper>
                <Box height={10} />
                <Box sx={{ padding: 2, marginLeft: 6 }}>
                  <Typography variant="subtitle2" fontWeight={600} sx={{ fontFamily: 'Raleway' }}>
                    {`I know, it's terrifying. This AI doesn't judge you and has infinite patience!`}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Container>
        </Stack>

        <Stack width="100%" alignItems="center">
          <Typography variant="h4" align="center" sx={{ fontFamily: 'Raleway' }}>
            Chat about anything
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <Translate />
              </ListItemIcon>
              <ListItemText primary="Get translations" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Spellcheck />
              </ListItemIcon>
              <ListItemText primary="Get feedback" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PlayArrow />
              </ListItemIcon>
              <ListItemText primary="Practice listening" />
            </ListItem>
          </List>
        </Stack>

        <Container maxWidth="md">
          <ConversationContextProvider conversation={demoConversationENShort}>
            <MessagesAreaDemo />
          </ConversationContextProvider>
        </Container>

        <List>
          <ListItem>
            <ListItemIcon>
              <Done />
            </ListItemIcon>
            <ListItemText primary="Speak with confidence" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText primary="Carry conversations with confidence" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText primary="Learn languages faster" />
          </ListItem>
        </List>

        <Box width="100%" backgroundColor={backgroundColorDarker} paddingY={8}>
          <Container maxWidth="sm">
            <Typography variant="h4" align="center" sx={{ fontFamily: 'Raleway' }}>
              Pay as you go, or subscribe
            </Typography>
            <Box height={16} />
            <Paper>
              <PaymentDialogContent hidePaymentButtons />
            </Paper>
          </Container>
        </Box>

        <Stack spacing={2} alignItems="center">
          <Button
            sx={{ padding: 2, fontSize: '1.05rem', maxWidth: 350, minWidth: 200 }}
            size="large"
            component={RouterLink}
            to="/register"
            variant="contained"
          >
            Start practicing for free
          </Button>
          <Button
            sx={{
              color: textColor,
              padding: 2,
              fontSize: '1.05rem',
              maxWidth: 350,
              minWidth: 200,
            }}
            size="large"
            component={RouterLink}
            to="/login"
            variant="text"
          >
            Sign in here
          </Button>
        </Stack>

        <Stack spacing={2} minHeight="calc(90vh - 110px)">
          <Box flexGrow={1} />
          <Typography variant="h4" align="center">
            FAQs
          </Typography>

          <Stack>
            <Accordion TransitionProps={{ unmountOnExit: true }} variant="outlined" sx={{ backgroundColor: 'inherit' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>What languages are available?</AccordionSummary>
              <AccordionDetails>
                English, Korean, Japanese, Mandarin, Tagalog/Wikang Filipino, Spanish, Italian, German, Russian,
                Finnish, Turkish, Hindi
                {` Let me know what you'd like to see and I'll try adding it.`}
              </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} variant="outlined" sx={{ backgroundColor: 'inherit' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>Is it free? (Limited free usage)</AccordionSummary>
              <AccordionDetails>
                You receive 1000 credits upon registration. This should allow one to use and explore app features.
                Subscribe for unlimited use at $14.99 monthly or make a one-time purchase of 2000 credits for $1.99.
              </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} variant="outlined" sx={{ backgroundColor: 'inherit' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>Why does this cost money?</AccordionSummary>
              <AccordionDetails>
                {`This app uses AI models that can only run on very expensive GPUs (see Nvidia H100). We're paying for the cost of running and maintaining datacenters with all of these GPUs.`}
              </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} variant="outlined" sx={{ backgroundColor: 'inherit' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                Can I learn a language as a complete beginner using this app?
              </AccordionSummary>
              <AccordionDetails>Probably not yet. You should be able to read your target language.</AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} variant="outlined" sx={{ backgroundColor: 'inherit' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>{`I'm not hearing any sounds.`}</AccordionSummary>
              <AccordionDetails>
                The problem will vary across browsers and devices. Some common ones:
                <List>
                  <ListItem>
                    <ListItemText
                      primary={`Your phone is in "Silent Mode"/"Do not Disturb" or something similar. For iPhones, check your notification slider.`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Your browser settings prevent websites from playing sounds. Find the setting controlling audio playback and enable it for chatfluently.com." />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} variant="outlined" sx={{ backgroundColor: 'inherit' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>{`The app can't hear my voice.`}</AccordionSummary>
              <AccordionDetails>
                The problem will vary across browsers and devices. Some common ones:
                <List>
                  <ListItem>
                    <ListItemText primary="Your browser settings prevent websites from using the microphone. Find the setting controlling microphone access and enable it for chatfluently.com." />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Box flexGrow={1} />
        </Stack>
      </Stack>

      <Grid container justifyContent="space-between" alignItems="center" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Grid>
          <Box padding={2}>
            <Stack direction="row" width="100%" alignItems="center" spacing={1}>
              <Typography>Questions? Bugs?</Typography>
              <Link color={textColor} href="https://discord.gg/cQ2GAtxWsc" target="_blank" rel="noopener noreferrer">
                Discord
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>Created by:</Typography>
              <Link color={textColor} href="https://twitter.com/youngtaiahn" target="_blank" rel="noopener noreferrer">
                @youngtaiahn
              </Link>
            </Stack>
          </Box>
        </Grid>
        <Grid>
          <Stack direction="row" padding={2} spacing={1}>
            <Copyright />
            <Typography>{`2023-${new Date().getFullYear()} YT Projects LLC. All rights reserved.`}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default LandingPage
