import React from 'react'
import { FormHelperText } from '@mui/material'

const EMAIL_REGEX = /^\S+@\S+\.\S+$/

class Utils {
  static validPassword(password) {
    if (!password) {
      return false
    }
    const minLength = password.length >= 8
    const maxLength = password.length <= 50
    const hasUppercase = /[A-Z]/.test(password)
    const hasLowercase = /[a-z]/.test(password)
    const hasNumber = /\d/.test(password)
    const hasSpecialChar = /[!@#$%^&*(){}[\]<>,.;:'"`~/?\\|]/.test(password)

    return minLength && maxLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar
  }

  static validUsername(username) {
    return username && username.trim() !== '' && username.length < 50
  }

  static validEmail(email) {
    return email && EMAIL_REGEX.test(email) && email.length < 50
  }

  static randomId() {
    return Math.random().toString(36).substring(2, 12)
  }

  static passwordHelperText() {
    return (
      <FormHelperText>
        Password requirements:
        <br />
        - Length: 8-50 characters
        <br />
        - At least 1 lowercase letter
        <br />
        - At least 1 uppercase letter
        <br />
        - At least 1 number
        <br />- At least 1 symbol
      </FormHelperText>
    )
  }

  static getFormattedDate(date) {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`
    return formattedDate
  }
}

export default Utils
