import React from 'react'

const creditsBalance = 0.0

const CreditBalanceContext = React.createContext({ balance: creditsBalance, updateBalance: () => null })

const CreditBalanceContextProvider = ({ data, setData, ...props }) => {
  const updateBalance = React.useCallback((newBalance) => setData(newBalance), [])
  const contextValue = React.useMemo(() => ({ balance: data, updateBalance }), [data, updateBalance])
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CreditBalanceContext.Provider value={contextValue} {...props} />
}

const useCreditBalanceContext = () => React.useContext(CreditBalanceContext)

export { CreditBalanceContextProvider, useCreditBalanceContext }
