import { Container, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from './contexts/UserContext'

const VerificationInvitationPage = () => {
  const navigate = useNavigate()
  const { userData } = useUserContext()

  React.useEffect(() => {
    if (!userData.loggedIn) {
      navigate('/')
    }
  }, [userData.loggedIn])

  return (
    <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography>Please check your email {userData.email} for an email verification link</Typography>
    </Container>
  )
}

export default VerificationInvitationPage
