import { Paper, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { BORDER_RADIUS } from '../constants'
import ConversationBubblesDemo from './ConversationBubblesDemo'

const MessagesAreaDemo = () => {
  const theme = useTheme()
  const { backgroundColor } = theme.palette

  const [viewportWidth, setViewportWidth] = React.useState(0)

  React.useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Paper
      sx={{
        backgroundColor,
        padding: 4,
        borderRadius: BORDER_RADIUS,
      }}
      variant="outlined"
    >
      <Stack spacing={2}>
        <ConversationBubblesDemo viewportWidth={viewportWidth} />
      </Stack>
    </Paper>
  )
}

export default MessagesAreaDemo
