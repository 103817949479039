import React from 'react'
import { Skeleton } from '@mui/material'
import LandingPageSkeleton from './LandingPageSkeleton'

const LandingPage = () => {
  const LandingPageLazy = React.lazy(() => import('../LandingPage'))
  return (
    <React.Suspense fallback={<LandingPageSkeleton />}>
      <LandingPageLazy />
    </React.Suspense>
  )
}
const LoginPage = () => {
  const LoginPageLazy = React.lazy(() => import('../LoginPage'))
  return (
    <React.Suspense fallback={<Skeleton variant="rounded" height={400} />}>
      <LoginPageLazy />
    </React.Suspense>
  )
}
const RegisterPage = () => {
  const RegisterPageLazy = React.lazy(() => import('../RegisterPage'))
  return (
    <React.Suspense fallback={<Skeleton variant="rounded" height={400} />}>
      <RegisterPageLazy />
    </React.Suspense>
  )
}
const Home = () => {
  const HomeLazy = React.lazy(() => import('../Home'))
  return (
    <React.Suspense fallback={<Skeleton variant="rounded" height={400} />}>
      <HomeLazy />
    </React.Suspense>
  )
}
const PushToTalkChatPage = () => {
  const Lazy = React.lazy(() => import('../conversation/PushToTalkChatPage'))
  return (
    <React.Suspense fallback={<Skeleton variant="rounded" height={400} />}>
      <Lazy />
    </React.Suspense>
  )
}
const ReviewPage = () => {
  const ReviewPageLazy = React.lazy(() => import('../review/ReviewPage'))
  return (
    <React.Suspense fallback={<Skeleton variant="rounded" height={400} />}>
      <ReviewPageLazy />
    </React.Suspense>
  )
}
const StudyPage = () => {
  const StudyPageLazy = React.lazy(() => import('../review/StudyPage'))
  return (
    <React.Suspense fallback={<Skeleton variant="rounded" height={400} />}>
      <StudyPageLazy />
    </React.Suspense>
  )
}
const CreateContentPage = () => {
  const CreateContentPageLazy = React.lazy(() => import('../review/CreateContentPage'))
  return (
    <React.Suspense fallback={<Skeleton variant="rounded" height={400} />}>
      <CreateContentPageLazy />
    </React.Suspense>
  )
}
const GuidedPracticePage = () => {
  const GuidedPracticePageLazy = React.lazy(() => import('../goals/GuidedPracticePage'))
  return (
    <React.Suspense fallback={<Skeleton variant="rounded" height={400} />}>
      <GuidedPracticePageLazy />
    </React.Suspense>
  )
}
const HandsFreeChatPage = () => {
  const Lazy = React.lazy(() => import('../conversation/HandsFreeChatPage'))
  return (
    <React.Suspense>
      <Lazy />
    </React.Suspense>
  )
}
const PronunciationLabPage = () => {
  const Lazy = React.lazy(() => import('../pronunciation/PronunciationLabPage'))
  return (
    <React.Suspense>
      <Lazy />
    </React.Suspense>
  )
}

export {
  LandingPage,
  LoginPage,
  RegisterPage,
  Home,
  PushToTalkChatPage,
  ReviewPage,
  StudyPage,
  CreateContentPage,
  GuidedPracticePage,
  HandsFreeChatPage,
  PronunciationLabPage,
}
