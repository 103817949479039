import React from 'react'
import { Alert, Stack, Typography } from '@mui/material'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

const ErrorFallback = ({ error }) => (
  <Alert severity="error">
    <Stack direction="row" justifyContent="space-between">
      <Typography>Something went wrong</Typography>
    </Stack>
    <Stack spacing={2}>
      <Typography>Error</Typography>
      <Typography fontFamily="monospace" color="error">
        {error.message}
      </Typography>
      <Typography>Stack trace</Typography>
      <Typography fontFamily="monospace" color="error">
        {error.stack}
      </Typography>
    </Stack>
  </Alert>
)

const ErrorBoundary = (props) => <ReactErrorBoundary FallbackComponent={ErrorFallback} {...props} />

export default ErrorBoundary
