import { Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useUserContext } from './contexts/UserContext'
import { TESTING } from './constants'

const LemonSqueezyCreditCheckout = () => {
  const theme = useTheme()
  const { userData } = useUserContext()

  const themeParameter = theme.palette.mode === 'light' ? 0 : 1
  const singlePaymentCheckoutURL = TESTING
    ? `https://chatfluently.lemonsqueezy.com/checkout/buy/4655c3ea-4746-4a2f-a5d3-294a99d18185?embed=1&media=0&logo=0&discount=0&dark=${themeParameter}&checkout[email]=${userData.email}`
    : `https://chatfluently.lemonsqueezy.com/checkout/buy/de62d3cc-d94f-4c3f-a4b2-5ef634a23c1a?embed=1&media=0&logo=0&discount=0&dark=${themeParameter}&checkout[email]=${userData.email}`

  return (
    <Button
      component={RouterLink}
      to={singlePaymentCheckoutURL}
      sx={{
        fontSize: '1.05rem',
        maxWidth: 400,
        padding: 2,
      }}
      className="lemonsqueezy-button"
      variant="outlined"
      color="success"
    >
      <Typography align="center">Buy Credits</Typography>
    </Button>
  )
}

export default LemonSqueezyCreditCheckout
