import React from 'react'

const ReviewContext = React.createContext({ selections: [], updateSelections: () => null })

const ReviewContextProvider = ({ selections, updateSelections, ...props }) => {
  const providerValue = React.useMemo(() => ({ selections, updateSelections }), [selections, updateSelections])
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ReviewContext.Provider value={providerValue} {...props} />
}

const useReviewContext = () => React.useContext(ReviewContext)

export { ReviewContextProvider, useReviewContext }
