import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  useMediaQuery,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import LabelValue from './LabelValue'
import TokenCache from './TokenCache'
import Utils from './Utils'
import { useUserContext } from './contexts/UserContext'
import { DOMAIN } from './constants'

export default function AccountDialog({ open, setOpen }) {
  const { userData, updateUserData } = useUserContext()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [newUsername, setNewUsername] = React.useState('')
  const [newEmail, setNewEmail] = React.useState('')
  const [validEmail, setValidEmail] = React.useState(false)
  const [newPassword, setNewPassword] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [alertData, setAlertData] = React.useState({ message: '', severity: '' })

  const emailIsValid = newEmail === '' || (validEmail && Utils.validEmail(newEmail))
  const passwordIsValid = newPassword === '' || Utils.validPassword(newPassword)

  const currentUsername = userData.username
  const currentEmail = userData.email

  const usernameIsValid = newUsername === '' || currentUsername !== newUsername
  const allEmpty = !newUsername && !newEmail && !newPassword
  const haveUpdates = newUsername !== '' || newEmail !== '' || newPassword !== ''
  const updatesValid = emailIsValid && passwordIsValid && usernameIsValid

  const handleUsernameChange = (event) => {
    setNewUsername(event.target.value)
  }
  const handleEmailChange = (event) => {
    setNewEmail(event.target.value)
    setValidEmail(event.target.validity.valid)
  }
  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value)
  }
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const updateAccountData = async () => {
    const cachedToken = TokenCache.getToken()
    if (cachedToken !== null && cachedToken !== '') {
      const requestConfigs = {
        method: 'POST',
        body: JSON.stringify({ newUsername, newEmail, newPassword }),
        headers: { Authorization: cachedToken, 'Content-Type': 'application/json' },
      }
      try {
        const response = await fetch(`${DOMAIN}/account/update`, requestConfigs)
        if (response.status === 200) {
          const responseData = await response.json()
          const { token, email, username } = responseData
          TokenCache.setToken(token)
          updateUserData({ ...userData, email, username, token })
          setAlertData({ message: 'Your account data was updated', severity: 'success' })
        } else if (response.status === 204) {
          setAlertData({ message: 'No changes were made', severity: 'info' })
        }
      } catch (error) {
        setAlertData({ message: error.message, severity: 'error' })
      }
      setTimeout(() => setAlertData({ message: '', severity: '' }), 8000)
    }
  }

  return (
    <Dialog open={open} fullScreen={fullScreen} maxWidth="sm" fullWidth>
      <Stack
        sx={{
          backgroundColor: theme.palette.backgroundColor.main,
          color: theme.palette.textColor.main,
          height: '100%',
        }}
      >
        <DialogTitle>Account Settings</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <LabelValue label="Username" value={currentUsername} />
            <FormControl>
              <InputLabel>New Username</InputLabel>
              <OutlinedInput label="New Username" fullWidth value={newUsername} onChange={handleUsernameChange} />
            </FormControl>
            <Divider />
            <LabelValue label="Email" value={currentEmail} />
            <FormControl>
              <InputLabel>New Email</InputLabel>
              <OutlinedInput
                label="New Email"
                fullWidth
                value={newEmail}
                error={newEmail !== '' && !emailIsValid}
                onChange={handleEmailChange}
              />
            </FormControl>
            <Divider />
            <FormControl>
              <InputLabel>New Password</InputLabel>
              <OutlinedInput
                label="New Password"
                type={newPassword === '' || showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
                value={newPassword}
                error={newPassword !== '' && !passwordIsValid}
                onChange={handlePasswordChange}
              />
              {newPassword !== '' && !passwordIsValid && Utils.passwordHelperText()}
            </FormControl>
            {alertData.message !== '' && (
              <Alert severity={alertData.severity} onClose={() => setAlertData({ message: '', severity: '' })}>
                {alertData.message}
              </Alert>
            )}
          </Stack>
        </DialogContent>
        <Box flexGrow={1} />
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: theme.palette.textColor.main }}>
            Close
          </Button>
          <Button
            disabled={allEmpty || !haveUpdates || !updatesValid}
            variant="contained"
            color="primary"
            onClick={updateAccountData}
          >
            Save Changes
          </Button>
        </DialogActions>
        <Box height={48} />
      </Stack>
    </Dialog>
  )
}
