import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const DrawerItem = ({ item }) => {
  const handleClick = () => {
    if (item.action) {
      item.action()
    }
  }

  return (
    <ListItem key={item.text} disablePadding>
      <ListItemButton component={item.toPath ? RouterLink : null} onClick={handleClick} to={item.toPath}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
  )
}

export default DrawerItem
