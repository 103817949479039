import { useTheme } from '@emotion/react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import LabelValue from './LabelValue'
import Utils from './Utils'
import { CODE_TO_DISPLAY_MAP, LANGUAGES } from './constants'
import SettingsCache from './SettingsCache'

export default function SettingsDialog({ open, setOpen }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [nativeLanguage, setNativeLanguage] = React.useState(SettingsCache.getNativeLanguage())

  const handleLanguageChange = (event) => {
    const newValue = event.target.value
    setNativeLanguage(newValue)
  }

  const saveChanges = () => {
    SettingsCache.updateNativeLanguage(nativeLanguage)
    setOpen(false)
  }

  return (
    <Dialog open={open} fullScreen={fullScreen} maxWidth="sm" fullWidth>
      <Stack
        sx={{
          backgroundColor: theme.palette.backgroundColor.main,
          color: theme.palette.textColor.main,
          height: '100%',
        }}
      >
        <DialogTitle>App Settings</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Divider />
            <Stack spacing={0}>
              <LabelValue label="My Native Language" value={CODE_TO_DISPLAY_MAP[nativeLanguage]} />
              <Typography variant="subtitle2">Translations and feedback will be in this language</Typography>
            </Stack>
            <FormControl>
              <InputLabel>Language</InputLabel>
              <Select label="Language" value={nativeLanguage} onChange={handleLanguageChange}>
                {LANGUAGES.map((l) => (
                  <MenuItem value={l} key={Utils.randomId()}>
                    {CODE_TO_DISPLAY_MAP[l]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <Box flexGrow={1} />
        <DialogActions>
          <Button onClick={() => setOpen(false)} sx={{ color: theme.palette.textColor.main }}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={saveChanges}>
            Save Changes
          </Button>
        </DialogActions>
        <Box height={48} />
      </Stack>
    </Dialog>
  )
}
