import { Card, CardActionArea, CardContent, CardMedia, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import micButtonImage from './resources/mic-button-isometric-1.jpg'
import soundPeaksImage from './resources/sound-peaks-1.jpg'

const ConversationBase = () => {
  const theme = useTheme()
  return (
    <Container maxWidth="md" sx={{ height: '100%', paddingX: 2, paddingTop: 2, paddingBottom: 4 }}>
      <Stack spacing={4} alignItems="center" paddingY={4}>
        <Card
          sx={{
            maxWidth: 450,
            backgroundColor: theme.palette.backgroundColor.main,
            color: theme.palette.textColor.main,
          }}
        >
          <CardActionArea component={RouterLink} to="/conversation">
            <CardMedia component="img" height={220} image={micButtonImage} alt="mic image" />
            <CardContent>
              <Typography variant="h5">Push-to-Talk Conversation</Typography>
              <Typography variant="subtitle2" gutterBottom>
                Control conversation flow with the push of a button
              </Typography>
              <Typography>
                This is better for beginners that need to look up translations or listen to messages more carefully
                during the conversation.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            maxWidth: 450,
            backgroundColor: theme.palette.backgroundColor.main,
            color: theme.palette.textColor.main,
          }}
        >
          <CardActionArea component={RouterLink} to="/hands-free-chat">
            <CardMedia component="img" height={220} image={soundPeaksImage} alt="sound peaks" />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Hands-Free Conversation
              </Typography>
              <Typography gutterBottom>
                The AI will always be listening to you unless it is speaking. You can have a chat even when your hands
                are occupied! This is best for intermediate to advanced speakers.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Tips
              </Typography>
              <Typography>
                This works best in a relatively quiet place without a lot of background noise. Using earphones or
                headphones will help ensure your the AI can hear your voice.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Stack>
    </Container>
  )
}

export default ConversationBase
