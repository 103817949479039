import React, { createContext, useContext, useCallback, useMemo } from 'react'

const GoalContext = createContext({ goalData: {}, updateGoalData: () => null })

const GoalContextProvider = ({ data, setData, ...props }) => {
  const updateGoalData = useCallback((updatedData) => {
    setData(updatedData)
  }, [])
  const contextValue = useMemo(() => ({ goalData: data, updateGoalData }), [data, updateGoalData])
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <GoalContext.Provider value={contextValue} {...props} />
}

const useGoalContext = () => useContext(GoalContext)

export { GoalContextProvider, useGoalContext }
