import { Button, Container, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <Container component="main" maxWidth="xs">
      <div style={{ marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h1" color="textPrimary" gutterBottom>
          404
        </Typography>
        <Typography variant="h4" gutterBottom>
          (°ロ°) !
        </Typography>
        <Typography variant="h5" color="textSecondary" paragraph>
          {`This page doesn't exist!`}
        </Typography>
        <Button size="large" variant="contained" color="primary" component={Link} to="/">
          Go Home
        </Button>
      </div>
    </Container>
  )
}
