import React from 'react'
import { Box } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import BasePage from './base/BasePage'
import { darkTheme, lightTheme } from './themes/Theme'
import { UserContexProvider } from './contexts/UserContext'
import { ReviewContextProvider } from './contexts/ReviewContext'
import ErrorBoundary from './ErrorBoundary'
import './FluentlyApp.css'
import { CreditBalanceContextProvider } from './contexts/CreditBalanceContext'
import { GoalContextProvider } from './contexts/GoalContext'

const queryClient = new QueryClient()

function FluentlyApp() {
  const [themeString, setThemeString] = React.useState(() => getStoredTheme())

  const getTheme = () => {
    if (themeString === 'light') {
      return lightTheme
    }
    if (themeString === 'dark') {
      return darkTheme
    }
    if (themeString === 'system') {
      const systemIsDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
      return systemIsDarkMode ? darkTheme : lightTheme
    }
    return lightTheme
  }
  const [theme, setTheme] = React.useState(() => getTheme())

  const [userData, setUserData] = React.useState({ loggedIn: false })
  const [balance, setBalance] = React.useState(0.0)
  const [reviewListSelections, setReviewListSelections] = React.useState([])
  const [goal, setGoal] = React.useState({})
  const updateSelections = React.useCallback((newValue) => setReviewListSelections(newValue), [])

  const handleThemeChange = (event, newState) => {
    if (newState) {
      setThemeString(newState)
      saveTheme(newState)
    }
  }

  React.useEffect(() => {
    if (themeString === 'system') {
      const media = window.matchMedia('(prefers-color-scheme: dark)')
      const isDarkMode = media.matches
      setTheme(isDarkMode ? darkTheme : lightTheme)

      media.addEventListener('change', (event) => setTheme(event.matches ? darkTheme : lightTheme))
    } else {
      setTheme(getTheme())
    }
  }, [themeString])

  return (
    <Box display="flex" height="100vh">
      <ErrorBoundary>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <UserContexProvider data={userData} setData={setUserData}>
              <GoalContextProvider data={goal} setData={setGoal}>
                <CreditBalanceContextProvider data={balance} setData={setBalance}>
                  <ReviewContextProvider selections={reviewListSelections} updateSelections={updateSelections}>
                    <QueryClientProvider client={queryClient}>
                      <BasePage handleThemeChange={handleThemeChange} currentTheme={themeString} />
                      <ReactQueryDevtools />
                    </QueryClientProvider>
                  </ReviewContextProvider>
                </CreditBalanceContextProvider>
              </GoalContextProvider>
            </UserContexProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </Box>
  )
}

function getStoredTheme() {
  const settingsValue = localStorage.getItem('fluentlySettings')
  const settings = settingsValue ? JSON.parse(settingsValue) : null
  return settings?.appTheme ? settings.appTheme : 'light'
}

function saveTheme(currentTheme) {
  localStorage.setItem('fluentlySettings', JSON.stringify({ appTheme: currentTheme }))
}

export default FluentlyApp
