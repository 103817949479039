import { Spellcheck, Translate } from '@mui/icons-material'
import { IconButton, Stack, Tooltip } from '@mui/material'
import React from 'react'

const BubbleOptionsDemo = ({
  content,
  translationState,
  setTranslationState,
  feedbackState,
  setFeedbackState,
  role,
}) => {
  const roleIsUser = role === 'user'

  // const [openEditDialog, setOpenEditDialog] = React.useState(false)

  const handleTranslationClick = async () => {
    const { translation, showTranslation } = translationState
    if (translation && translation !== '') {
      setTranslationState({ translation, showTranslation: !showTranslation, preparingTranslation: false })
    } else if (!content) {
      setTranslationState({ translation: '', showTranslation: !showTranslation, preparingTranslation: false })
    } else {
      setTranslationState({
        translation: 'Translation will be here',
        showTranslation: true,
        preparingTranslation: false,
      })
    }
  }

  const handleFeedbackClick = async () => {
    const { feedback, showFeedback } = feedbackState
    if (feedback && feedback !== '') {
      setFeedbackState({ feedback, showFeedback: !showFeedback, preparingFeedback: false })
    } else {
      setFeedbackState({
        feedback: 'Feedback will be here',
        showFeedback: true,
        preparingFeedback: false,
      })
    }
  }

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Translate" placement="top">
        <IconButton onClick={handleTranslationClick} disabled={translationState.preparingTranslation}>
          <Translate />
        </IconButton>
      </Tooltip>
      {roleIsUser && (
        <Tooltip title="Correct Me" placement="top">
          <IconButton onClick={handleFeedbackClick} disabled={feedbackState.preparingFeedback}>
            <Spellcheck />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}

export default BubbleOptionsDemo
