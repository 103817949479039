import { Alert, Box, Collapse, Divider, Skeleton, Snackbar, Stack, Typography } from '@mui/material'
import React from 'react'
import Utils from '../Utils'
import ConversationService from '../service/ConversationService'
import BubbleOptionsDemo from './BubbleOptionsDemo'
import SpeechControlButtonsDemo from './SpeechControlButtonsDemo'

const ConversationBubbleDemo = ({ content, messageId, styling, position, role }) => {
  const [translationState, setTranslationState] = React.useState({
    showTranslation: false,
    translation: '',
    preparingTranslation: false,
  })
  const [feedbackState, setFeedbackState] = React.useState({
    showFeedback: false,
    feedback: '',
    preparingFeedback: false,
  })
  const [speech, setSpeech] = React.useState(null)
  const [speaking, setSpeaking] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')

  const { translation, showTranslation, preparingTranslation } = translationState
  const { feedback, showFeedback, preparingFeedback } = feedbackState
  const roleIsUser = role === 'user'
  const getSpeech = async () =>
    ConversationService.getSpeechForDemo(
      messageId,
      () => setSpeaking(true),
      () => setSpeaking(false)
    )

  return (
    <Box key={`interaction-${Utils.randomId()}`} sx={{ justifyContent: position, display: 'flex' }}>
      <Snackbar
        open={alertMessage !== undefined && alertMessage !== null && alertMessage !== ''}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setAlertMessage('')}
      >
        <Alert severity="error" onClose={() => setAlertMessage('')}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box sx={styling}>
        <Stack direction="row" spacing={1}>
          <Box flexGrow={1} />
          <BubbleOptionsDemo
            content={content}
            translationState={translationState}
            setTranslationState={setTranslationState}
            feedbackState={feedbackState}
            setFeedbackState={setFeedbackState}
            role={role}
          />
          <SpeechControlButtonsDemo
            speech={speech}
            setSpeech={setSpeech}
            getSpeech={getSpeech}
            speaking={speaking}
            setSpeaking={setSpeaking}
          />
        </Stack>
        <Typography>{content}</Typography>
        <Collapse in={showTranslation}>
          <Divider sx={{ paddingY: 1 }} />
          {preparingTranslation ? (
            <TranslationLoadingSkeleton />
          ) : (
            <Typography sx={{ paddingY: 2 }}>{translation}</Typography>
          )}
        </Collapse>
        {roleIsUser && (
          <Collapse in={showFeedback}>
            <Divider sx={{ paddingY: 1 }} />
            {preparingFeedback ? (
              <FeedbackLoadingSkeleton />
            ) : (
              <Typography sx={{ paddingTop: 2 }}>{feedback}</Typography>
            )}
          </Collapse>
        )}
      </Box>
    </Box>
  )
}

const TranslationLoadingSkeleton = () => (
  <Stack paddingTop={2}>
    <Skeleton height={30} variant="text" />
    <Skeleton height={30} width="80%" variant="text" />
  </Stack>
)

const FeedbackLoadingSkeleton = () => (
  <Stack paddingTop={2}>
    <Skeleton height={30} variant="text" />
    <Skeleton height={30} variant="text" />
    <Skeleton height={30} width="80%" variant="text" />
  </Stack>
)

export default ConversationBubbleDemo
